
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexiydiggTRFSMeta } from "/Users/gabriel/Documents/GitHub/sorrifacil-site/pages/admin/banners/[id]/index.vue?macro=true";
import { default as indexOEd3Y6UjRnMeta } from "/Users/gabriel/Documents/GitHub/sorrifacil-site/pages/admin/banners/index.vue?macro=true";
import { default as _91imagem_93iMJXTW1AyVMeta } from "/Users/gabriel/Documents/GitHub/sorrifacil-site/pages/admin/clinicas/[clinica]/imagens/[imagem].vue?macro=true";
import { default as indexVoaiWgdMidMeta } from "/Users/gabriel/Documents/GitHub/sorrifacil-site/pages/admin/clinicas/[clinica]/imagens/index.vue?macro=true";
import { default as indexR30nsCtnM9Meta } from "/Users/gabriel/Documents/GitHub/sorrifacil-site/pages/admin/clinicas/[clinica]/index.vue?macro=true";
import { default as indexMceSdSgvf3Meta } from "/Users/gabriel/Documents/GitHub/sorrifacil-site/pages/admin/clinicas/index.vue?macro=true";
import { default as indexYvPgSC5vPkMeta } from "/Users/gabriel/Documents/GitHub/sorrifacil-site/pages/admin/depoimentos/[id]/index.vue?macro=true";
import { default as indexxp8C3xalEYMeta } from "/Users/gabriel/Documents/GitHub/sorrifacil-site/pages/admin/depoimentos/index.vue?macro=true";
import { default as indexhtGAuy1MY9Meta } from "/Users/gabriel/Documents/GitHub/sorrifacil-site/pages/admin/index.vue?macro=true";
import { default as indexG14kSBCu8XMeta } from "/Users/gabriel/Documents/GitHub/sorrifacil-site/pages/admin/leads/[id]/index.vue?macro=true";
import { default as index4CXTO0BIgYMeta } from "/Users/gabriel/Documents/GitHub/sorrifacil-site/pages/admin/leads/index.vue?macro=true";
import { default as indexsn7uhKvVOfMeta } from "/Users/gabriel/Documents/GitHub/sorrifacil-site/pages/admin/login/index.vue?macro=true";
import { default as indexDvD2Dij4KrMeta } from "/Users/gabriel/Documents/GitHub/sorrifacil-site/pages/admin/posts/[id]/index.vue?macro=true";
import { default as indexCnOBqgosBUMeta } from "/Users/gabriel/Documents/GitHub/sorrifacil-site/pages/admin/posts/index.vue?macro=true";
import { default as indexijEYBvETKeMeta } from "/Users/gabriel/Documents/GitHub/sorrifacil-site/pages/admin/tratamentos/[tratamento]/index.vue?macro=true";
import { default as _91tipo_93b0RZRfwhvuMeta } from "/Users/gabriel/Documents/GitHub/sorrifacil-site/pages/admin/tratamentos/[tratamento]/tipos/[tipo].vue?macro=true";
import { default as indexiFD3C8UwPHMeta } from "/Users/gabriel/Documents/GitHub/sorrifacil-site/pages/admin/tratamentos/[tratamento]/tipos/index.vue?macro=true";
import { default as _91vantagem_938Gzc6nsMSXMeta } from "/Users/gabriel/Documents/GitHub/sorrifacil-site/pages/admin/tratamentos/[tratamento]/vantagens/[vantagem].vue?macro=true";
import { default as indexVTYEayiZCXMeta } from "/Users/gabriel/Documents/GitHub/sorrifacil-site/pages/admin/tratamentos/[tratamento]/vantagens/index.vue?macro=true";
import { default as indexJpdNXZqNdRMeta } from "/Users/gabriel/Documents/GitHub/sorrifacil-site/pages/admin/tratamentos/index.vue?macro=true";
import { default as indexPTxiJ6x7F5Meta } from "/Users/gabriel/Documents/GitHub/sorrifacil-site/pages/admin/usuarios/[id]/index.vue?macro=true";
import { default as indexeS9xCKHfyrMeta } from "/Users/gabriel/Documents/GitHub/sorrifacil-site/pages/admin/usuarios/index.vue?macro=true";
import { default as agradecimentobnUu5Wuz3hMeta } from "/Users/gabriel/Documents/GitHub/sorrifacil-site/pages/agradecimento.vue?macro=true";
import { default as _91slug_931Rl53fH73GMeta } from "/Users/gabriel/Documents/GitHub/sorrifacil-site/pages/blog/[slug].vue?macro=true";
import { default as indexNQKAP6gD4cMeta } from "/Users/gabriel/Documents/GitHub/sorrifacil-site/pages/blog/index.vue?macro=true";
import { default as _91slug_93grsZZA4H3zMeta } from "/Users/gabriel/Documents/GitHub/sorrifacil-site/pages/clinicas/[slug].vue?macro=true";
import { default as indexCS1xs1owdvMeta } from "/Users/gabriel/Documents/GitHub/sorrifacil-site/pages/clinicas/index.vue?macro=true";
import { default as contatopF59nLWNC3Meta } from "/Users/gabriel/Documents/GitHub/sorrifacil-site/pages/contato.vue?macro=true";
import { default as franquiasqtiiDQ3lXMeta } from "/Users/gabriel/Documents/GitHub/sorrifacil-site/pages/franquia.vue?macro=true";
import { default as indexuUrwAf5CzMMeta } from "/Users/gabriel/Documents/GitHub/sorrifacil-site/pages/index.vue?macro=true";
import { default as privacidadedB0zQb5DtKMeta } from "/Users/gabriel/Documents/GitHub/sorrifacil-site/pages/privacidade.vue?macro=true";
import { default as sobre1qLgrB5r7zMeta } from "/Users/gabriel/Documents/GitHub/sorrifacil-site/pages/sobre.vue?macro=true";
import { default as _91slug_93AnNApSFokCMeta } from "/Users/gabriel/Documents/GitHub/sorrifacil-site/pages/tratamentos/[slug].vue?macro=true";
import { default as indexWtKBrPPtmrMeta } from "/Users/gabriel/Documents/GitHub/sorrifacil-site/pages/tratamentos/index.vue?macro=true";
import { default as component_45stubTPYIMbKLTuMeta } from "/Users/gabriel/Documents/GitHub/sorrifacil-site/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubTPYIMbKLTu } from "/Users/gabriel/Documents/GitHub/sorrifacil-site/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "admin-banners-id___pt",
    path: "/admin/banners/:id()",
    meta: indexiydiggTRFSMeta || {},
    component: () => import("/Users/gabriel/Documents/GitHub/sorrifacil-site/pages/admin/banners/[id]/index.vue")
  },
  {
    name: "admin-banners-id___es",
    path: "/es/admin/banners/:id()",
    meta: indexiydiggTRFSMeta || {},
    component: () => import("/Users/gabriel/Documents/GitHub/sorrifacil-site/pages/admin/banners/[id]/index.vue")
  },
  {
    name: "admin-banners___pt",
    path: "/admin/banners",
    meta: indexOEd3Y6UjRnMeta || {},
    component: () => import("/Users/gabriel/Documents/GitHub/sorrifacil-site/pages/admin/banners/index.vue")
  },
  {
    name: "admin-banners___es",
    path: "/es/admin/banners",
    meta: indexOEd3Y6UjRnMeta || {},
    component: () => import("/Users/gabriel/Documents/GitHub/sorrifacil-site/pages/admin/banners/index.vue")
  },
  {
    name: "admin-clinicas-clinica-imagens-imagem___pt",
    path: "/admin/clinicas/:clinica()/imagens/:imagem()",
    meta: _91imagem_93iMJXTW1AyVMeta || {},
    component: () => import("/Users/gabriel/Documents/GitHub/sorrifacil-site/pages/admin/clinicas/[clinica]/imagens/[imagem].vue")
  },
  {
    name: "admin-clinicas-clinica-imagens-imagem___es",
    path: "/es/admin/clinicas/:clinica()/imagens/:imagem()",
    meta: _91imagem_93iMJXTW1AyVMeta || {},
    component: () => import("/Users/gabriel/Documents/GitHub/sorrifacil-site/pages/admin/clinicas/[clinica]/imagens/[imagem].vue")
  },
  {
    name: "admin-clinicas-clinica-imagens___pt",
    path: "/admin/clinicas/:clinica()/imagens",
    meta: indexVoaiWgdMidMeta || {},
    component: () => import("/Users/gabriel/Documents/GitHub/sorrifacil-site/pages/admin/clinicas/[clinica]/imagens/index.vue")
  },
  {
    name: "admin-clinicas-clinica-imagens___es",
    path: "/es/admin/clinicas/:clinica()/imagens",
    meta: indexVoaiWgdMidMeta || {},
    component: () => import("/Users/gabriel/Documents/GitHub/sorrifacil-site/pages/admin/clinicas/[clinica]/imagens/index.vue")
  },
  {
    name: "admin-clinicas-clinica___pt",
    path: "/admin/clinicas/:clinica()",
    meta: indexR30nsCtnM9Meta || {},
    component: () => import("/Users/gabriel/Documents/GitHub/sorrifacil-site/pages/admin/clinicas/[clinica]/index.vue")
  },
  {
    name: "admin-clinicas-clinica___es",
    path: "/es/admin/clinicas/:clinica()",
    meta: indexR30nsCtnM9Meta || {},
    component: () => import("/Users/gabriel/Documents/GitHub/sorrifacil-site/pages/admin/clinicas/[clinica]/index.vue")
  },
  {
    name: "admin-clinicas___pt",
    path: "/admin/clinicas",
    meta: indexMceSdSgvf3Meta || {},
    component: () => import("/Users/gabriel/Documents/GitHub/sorrifacil-site/pages/admin/clinicas/index.vue")
  },
  {
    name: "admin-clinicas___es",
    path: "/es/admin/clinicas",
    meta: indexMceSdSgvf3Meta || {},
    component: () => import("/Users/gabriel/Documents/GitHub/sorrifacil-site/pages/admin/clinicas/index.vue")
  },
  {
    name: "admin-depoimentos-id___pt",
    path: "/admin/depoimentos/:id()",
    meta: indexYvPgSC5vPkMeta || {},
    component: () => import("/Users/gabriel/Documents/GitHub/sorrifacil-site/pages/admin/depoimentos/[id]/index.vue")
  },
  {
    name: "admin-depoimentos-id___es",
    path: "/es/admin/depoimentos/:id()",
    meta: indexYvPgSC5vPkMeta || {},
    component: () => import("/Users/gabriel/Documents/GitHub/sorrifacil-site/pages/admin/depoimentos/[id]/index.vue")
  },
  {
    name: "admin-depoimentos___pt",
    path: "/admin/depoimentos",
    meta: indexxp8C3xalEYMeta || {},
    component: () => import("/Users/gabriel/Documents/GitHub/sorrifacil-site/pages/admin/depoimentos/index.vue")
  },
  {
    name: "admin-depoimentos___es",
    path: "/es/admin/depoimentos",
    meta: indexxp8C3xalEYMeta || {},
    component: () => import("/Users/gabriel/Documents/GitHub/sorrifacil-site/pages/admin/depoimentos/index.vue")
  },
  {
    name: "admin",
    path: "/admin",
    meta: indexhtGAuy1MY9Meta || {},
    component: () => import("/Users/gabriel/Documents/GitHub/sorrifacil-site/pages/admin/index.vue")
  },
  {
    name: "admin-leads-id___pt",
    path: "/admin/leads/:id()",
    meta: indexG14kSBCu8XMeta || {},
    component: () => import("/Users/gabriel/Documents/GitHub/sorrifacil-site/pages/admin/leads/[id]/index.vue")
  },
  {
    name: "admin-leads-id___es",
    path: "/es/admin/leads/:id()",
    meta: indexG14kSBCu8XMeta || {},
    component: () => import("/Users/gabriel/Documents/GitHub/sorrifacil-site/pages/admin/leads/[id]/index.vue")
  },
  {
    name: "admin-leads",
    path: "/admin/leads",
    meta: index4CXTO0BIgYMeta || {},
    component: () => import("/Users/gabriel/Documents/GitHub/sorrifacil-site/pages/admin/leads/index.vue")
  },
  {
    name: "admin-login___pt",
    path: "/admin/login",
    meta: indexsn7uhKvVOfMeta || {},
    component: () => import("/Users/gabriel/Documents/GitHub/sorrifacil-site/pages/admin/login/index.vue")
  },
  {
    name: "admin-login___es",
    path: "/es/admin/login",
    meta: indexsn7uhKvVOfMeta || {},
    component: () => import("/Users/gabriel/Documents/GitHub/sorrifacil-site/pages/admin/login/index.vue")
  },
  {
    name: "admin-posts-id___pt",
    path: "/admin/posts/:id()",
    meta: indexDvD2Dij4KrMeta || {},
    component: () => import("/Users/gabriel/Documents/GitHub/sorrifacil-site/pages/admin/posts/[id]/index.vue")
  },
  {
    name: "admin-posts-id___es",
    path: "/es/admin/posts/:id()",
    meta: indexDvD2Dij4KrMeta || {},
    component: () => import("/Users/gabriel/Documents/GitHub/sorrifacil-site/pages/admin/posts/[id]/index.vue")
  },
  {
    name: "admin-posts___pt",
    path: "/admin/posts",
    meta: indexCnOBqgosBUMeta || {},
    component: () => import("/Users/gabriel/Documents/GitHub/sorrifacil-site/pages/admin/posts/index.vue")
  },
  {
    name: "admin-posts___es",
    path: "/es/admin/posts",
    meta: indexCnOBqgosBUMeta || {},
    component: () => import("/Users/gabriel/Documents/GitHub/sorrifacil-site/pages/admin/posts/index.vue")
  },
  {
    name: "admin-tratamentos-tratamento___pt",
    path: "/admin/tratamentos/:tratamento()",
    meta: indexijEYBvETKeMeta || {},
    component: () => import("/Users/gabriel/Documents/GitHub/sorrifacil-site/pages/admin/tratamentos/[tratamento]/index.vue")
  },
  {
    name: "admin-tratamentos-tratamento___es",
    path: "/es/admin/tratamentos/:tratamento()",
    meta: indexijEYBvETKeMeta || {},
    component: () => import("/Users/gabriel/Documents/GitHub/sorrifacil-site/pages/admin/tratamentos/[tratamento]/index.vue")
  },
  {
    name: "admin-tratamentos-tratamento-tipos-tipo___pt",
    path: "/admin/tratamentos/:tratamento()/tipos/:tipo()",
    meta: _91tipo_93b0RZRfwhvuMeta || {},
    component: () => import("/Users/gabriel/Documents/GitHub/sorrifacil-site/pages/admin/tratamentos/[tratamento]/tipos/[tipo].vue")
  },
  {
    name: "admin-tratamentos-tratamento-tipos-tipo___es",
    path: "/es/admin/tratamentos/:tratamento()/tipos/:tipo()",
    meta: _91tipo_93b0RZRfwhvuMeta || {},
    component: () => import("/Users/gabriel/Documents/GitHub/sorrifacil-site/pages/admin/tratamentos/[tratamento]/tipos/[tipo].vue")
  },
  {
    name: "admin-tratamentos-tratamento-tipos___pt",
    path: "/admin/tratamentos/:tratamento()/tipos",
    meta: indexiFD3C8UwPHMeta || {},
    component: () => import("/Users/gabriel/Documents/GitHub/sorrifacil-site/pages/admin/tratamentos/[tratamento]/tipos/index.vue")
  },
  {
    name: "admin-tratamentos-tratamento-tipos___es",
    path: "/es/admin/tratamentos/:tratamento()/tipos",
    meta: indexiFD3C8UwPHMeta || {},
    component: () => import("/Users/gabriel/Documents/GitHub/sorrifacil-site/pages/admin/tratamentos/[tratamento]/tipos/index.vue")
  },
  {
    name: "admin-tratamentos-tratamento-vantagens-vantagem___pt",
    path: "/admin/tratamentos/:tratamento()/vantagens/:vantagem()",
    meta: _91vantagem_938Gzc6nsMSXMeta || {},
    component: () => import("/Users/gabriel/Documents/GitHub/sorrifacil-site/pages/admin/tratamentos/[tratamento]/vantagens/[vantagem].vue")
  },
  {
    name: "admin-tratamentos-tratamento-vantagens-vantagem___es",
    path: "/es/admin/tratamentos/:tratamento()/vantagens/:vantagem()",
    meta: _91vantagem_938Gzc6nsMSXMeta || {},
    component: () => import("/Users/gabriel/Documents/GitHub/sorrifacil-site/pages/admin/tratamentos/[tratamento]/vantagens/[vantagem].vue")
  },
  {
    name: "admin-tratamentos-tratamento-vantagens___pt",
    path: "/admin/tratamentos/:tratamento()/vantagens",
    meta: indexVTYEayiZCXMeta || {},
    component: () => import("/Users/gabriel/Documents/GitHub/sorrifacil-site/pages/admin/tratamentos/[tratamento]/vantagens/index.vue")
  },
  {
    name: "admin-tratamentos-tratamento-vantagens___es",
    path: "/es/admin/tratamentos/:tratamento()/vantagens",
    meta: indexVTYEayiZCXMeta || {},
    component: () => import("/Users/gabriel/Documents/GitHub/sorrifacil-site/pages/admin/tratamentos/[tratamento]/vantagens/index.vue")
  },
  {
    name: "admin-tratamentos___pt",
    path: "/admin/tratamentos",
    meta: indexJpdNXZqNdRMeta || {},
    component: () => import("/Users/gabriel/Documents/GitHub/sorrifacil-site/pages/admin/tratamentos/index.vue")
  },
  {
    name: "admin-tratamentos___es",
    path: "/es/admin/tratamentos",
    meta: indexJpdNXZqNdRMeta || {},
    component: () => import("/Users/gabriel/Documents/GitHub/sorrifacil-site/pages/admin/tratamentos/index.vue")
  },
  {
    name: "admin-usuarios-id___pt",
    path: "/admin/usuarios/:id()",
    meta: indexPTxiJ6x7F5Meta || {},
    component: () => import("/Users/gabriel/Documents/GitHub/sorrifacil-site/pages/admin/usuarios/[id]/index.vue")
  },
  {
    name: "admin-usuarios-id___es",
    path: "/es/admin/usuarios/:id()",
    meta: indexPTxiJ6x7F5Meta || {},
    component: () => import("/Users/gabriel/Documents/GitHub/sorrifacil-site/pages/admin/usuarios/[id]/index.vue")
  },
  {
    name: "admin-usuarios___pt",
    path: "/admin/usuarios",
    meta: indexeS9xCKHfyrMeta || {},
    component: () => import("/Users/gabriel/Documents/GitHub/sorrifacil-site/pages/admin/usuarios/index.vue")
  },
  {
    name: "admin-usuarios___es",
    path: "/es/admin/usuarios",
    meta: indexeS9xCKHfyrMeta || {},
    component: () => import("/Users/gabriel/Documents/GitHub/sorrifacil-site/pages/admin/usuarios/index.vue")
  },
  {
    name: "agradecimento___pt",
    path: "/agradecimento",
    component: () => import("/Users/gabriel/Documents/GitHub/sorrifacil-site/pages/agradecimento.vue")
  },
  {
    name: "agradecimento___es",
    path: "/es/agradecimento",
    component: () => import("/Users/gabriel/Documents/GitHub/sorrifacil-site/pages/agradecimento.vue")
  },
  {
    name: "blog-slug___pt",
    path: "/blog/:slug()",
    component: () => import("/Users/gabriel/Documents/GitHub/sorrifacil-site/pages/blog/[slug].vue")
  },
  {
    name: "blog-slug___es",
    path: "/es/blog/:slug()",
    component: () => import("/Users/gabriel/Documents/GitHub/sorrifacil-site/pages/blog/[slug].vue")
  },
  {
    name: "blog___pt",
    path: "/blog",
    component: () => import("/Users/gabriel/Documents/GitHub/sorrifacil-site/pages/blog/index.vue")
  },
  {
    name: "blog___es",
    path: "/es/blog",
    component: () => import("/Users/gabriel/Documents/GitHub/sorrifacil-site/pages/blog/index.vue")
  },
  {
    name: "clinicas-slug___pt",
    path: "/clinicas/:slug()",
    meta: _91slug_93grsZZA4H3zMeta || {},
    component: () => import("/Users/gabriel/Documents/GitHub/sorrifacil-site/pages/clinicas/[slug].vue")
  },
  {
    name: "clinicas-slug___es",
    path: "/es/clinicas/:slug()",
    meta: _91slug_93grsZZA4H3zMeta || {},
    component: () => import("/Users/gabriel/Documents/GitHub/sorrifacil-site/pages/clinicas/[slug].vue")
  },
  {
    name: "clinicas___pt",
    path: "/clinicas",
    component: () => import("/Users/gabriel/Documents/GitHub/sorrifacil-site/pages/clinicas/index.vue")
  },
  {
    name: "clinicas___es",
    path: "/es/clinicas",
    component: () => import("/Users/gabriel/Documents/GitHub/sorrifacil-site/pages/clinicas/index.vue")
  },
  {
    name: "contato___pt",
    path: "/contato",
    component: () => import("/Users/gabriel/Documents/GitHub/sorrifacil-site/pages/contato.vue")
  },
  {
    name: "contato___es",
    path: "/es/contato",
    component: () => import("/Users/gabriel/Documents/GitHub/sorrifacil-site/pages/contato.vue")
  },
  {
    name: "franquia___pt",
    path: "/franquia",
    meta: franquiasqtiiDQ3lXMeta || {},
    component: () => import("/Users/gabriel/Documents/GitHub/sorrifacil-site/pages/franquia.vue")
  },
  {
    name: "franquia___es",
    path: "/es/franquia",
    meta: franquiasqtiiDQ3lXMeta || {},
    component: () => import("/Users/gabriel/Documents/GitHub/sorrifacil-site/pages/franquia.vue")
  },
  {
    name: "index___pt",
    path: "/",
    component: () => import("/Users/gabriel/Documents/GitHub/sorrifacil-site/pages/index.vue")
  },
  {
    name: "index___es",
    path: "/es",
    component: () => import("/Users/gabriel/Documents/GitHub/sorrifacil-site/pages/index.vue")
  },
  {
    name: "privacidade___pt",
    path: "/privacidade",
    component: () => import("/Users/gabriel/Documents/GitHub/sorrifacil-site/pages/privacidade.vue")
  },
  {
    name: "privacidade___es",
    path: "/es/privacidade",
    component: () => import("/Users/gabriel/Documents/GitHub/sorrifacil-site/pages/privacidade.vue")
  },
  {
    name: "sobre___pt",
    path: "/sobre",
    component: () => import("/Users/gabriel/Documents/GitHub/sorrifacil-site/pages/sobre.vue")
  },
  {
    name: "sobre___es",
    path: "/es/sobre",
    component: () => import("/Users/gabriel/Documents/GitHub/sorrifacil-site/pages/sobre.vue")
  },
  {
    name: "tratamentos-slug___pt",
    path: "/tratamentos/:slug()",
    component: () => import("/Users/gabriel/Documents/GitHub/sorrifacil-site/pages/tratamentos/[slug].vue")
  },
  {
    name: "tratamentos-slug___es",
    path: "/es/tratamentos/:slug()",
    component: () => import("/Users/gabriel/Documents/GitHub/sorrifacil-site/pages/tratamentos/[slug].vue")
  },
  {
    name: "tratamentos___pt",
    path: "/tratamentos",
    component: () => import("/Users/gabriel/Documents/GitHub/sorrifacil-site/pages/tratamentos/index.vue")
  },
  {
    name: "tratamentos___es",
    path: "/es/tratamentos",
    component: () => import("/Users/gabriel/Documents/GitHub/sorrifacil-site/pages/tratamentos/index.vue")
  },
  {
    name: component_45stubTPYIMbKLTuMeta?.name,
    path: "/saiba-mais-site-v1",
    component: component_45stubTPYIMbKLTu
  }
]