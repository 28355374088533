import validate from "/Users/gabriel/Documents/GitHub/sorrifacil-site/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45global from "/Users/gabriel/Documents/GitHub/sorrifacil-site/middleware/auth.global.js";
import redirect_45global from "/Users/gabriel/Documents/GitHub/sorrifacil-site/middleware/redirect.global.js";
import trailingslash_45global from "/Users/gabriel/Documents/GitHub/sorrifacil-site/middleware/trailingslash.global.js";
import manifest_45route_45rule from "/Users/gabriel/Documents/GitHub/sorrifacil-site/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45global,
  redirect_45global,
  trailingslash_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}