import revive_payload_client_4sVQNw7RlN from "/Users/gabriel/Documents/GitHub/sorrifacil-site/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/Users/gabriel/Documents/GitHub/sorrifacil-site/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/Users/gabriel/Documents/GitHub/sorrifacil-site/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import navigation_repaint_client_bs2bWDGRIE from "/Users/gabriel/Documents/GitHub/sorrifacil-site/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/Users/gabriel/Documents/GitHub/sorrifacil-site/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/Users/gabriel/Documents/GitHub/sorrifacil-site/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/Users/gabriel/Documents/GitHub/sorrifacil-site/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/Users/gabriel/Documents/GitHub/sorrifacil-site/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_tbFNToZNim from "/Users/gabriel/Documents/GitHub/sorrifacil-site/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import plugin_client_LcKgStRyi6 from "/Users/gabriel/Documents/GitHub/sorrifacil-site/node_modules/nuxt-gtag/dist/runtime/plugin.client.mjs";
import composition_sLxaNGmlSL from "/Users/gabriel/Documents/GitHub/sorrifacil-site/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_yfWm7jX06p from "/Users/gabriel/Documents/GitHub/sorrifacil-site/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_HQNyLQlVwt from "/Users/gabriel/Documents/GitHub/sorrifacil-site/node_modules/nuxt3-meta-pixel/dist/runtime/plugin.mjs";
import plugin_hwlJ5PmHMh from "/Users/gabriel/Documents/GitHub/sorrifacil-site/node_modules/nuxt-lazy-load/dist/runtime/plugin.mjs";
import bootstrap_client_zGQC9AE5yr from "/Users/gabriel/Documents/GitHub/sorrifacil-site/plugins/bootstrap.client.js";
import device_VEtfIepYun from "/Users/gabriel/Documents/GitHub/sorrifacil-site/plugins/device.js";
import maska_r8BHBbSmHt from "/Users/gabriel/Documents/GitHub/sorrifacil-site/plugins/maska.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_tbFNToZNim,
  plugin_client_LcKgStRyi6,
  composition_sLxaNGmlSL,
  i18n_yfWm7jX06p,
  plugin_HQNyLQlVwt,
  plugin_hwlJ5PmHMh,
  bootstrap_client_zGQC9AE5yr,
  device_VEtfIepYun,
  maska_r8BHBbSmHt
]